import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { Spin as Hamburger } from 'hamburger-react'
import MenuList from './MenuList'

import { GiRotaryPhone } from 'react-icons/gi'
import IconsRedes from '../IconRedes'

const HeaderContent = () => {

  const { rpdata } = useContext(GlobalDataContext)
  const [isOpen, setOpen] = useState(false)

  return (
    <section className='absolute w-full z-50 '>

      <div className='flex justify-between items-start md:items-center bgThree'>
        <div className='bgGradienteBlock svgHeader1Left h-[80px] hidden md:block w-[20%]'></div>
        <div className='w-[90%] md:w-[60%] h-full hidden md:block'>
          <MenuList classes={'w-full h-full flex justify-center gap-5 px-14 text-white'} />
        </div>

        <div className='block md:hidden pl-3 py-3'>
          <div className='flex flex-col w-[90%] items-start text-white'>

            <div className='flex flex-col justify-end gap-0'>
              <div className='flex items-center gap-x-3'>
                <GiRotaryPhone fontSize={28} />
                <ul className='flex gap-3'>
                  {
                    rpdata?.dbPrincipal?.phones?.slice(0, 1)?.map((phone, index) => {
                      return (
                        <li key={index}>
                          <a href={`tel:+1${phone.phone}`}>
                            {phone.phone}
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>

            <ul className='flex'>
              {
                rpdata?.dbPrincipal?.emails?.slice(0, 1)?.map((email, index) => {
                  return (
                    <li key={index}>
                      <a href={`tel:+1${email.email}`}>
                        {email.email}
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className={` svgHeader1Right h-[80px] w-[25%] md:w-[20%] ${isOpen ? 'relative z-50 bg-[#222]' : 'bgGradienteBlock'}`}>
          <div className={`md:hidden flex justify-end pr-2 items-center w-full h-full ${isOpen ? 'text-white' : ''}`}>
            <div className={`${!isOpen ? 'bg-white rounded-lg' : ''}`}>
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
          </div>
        </div>
      </div>

      <div className='hidden md:block bgSecondary w-[85%] mr-auto svgHeader1Info py-2'>
        <div className='flex flex-col items-end w-[85%] mx-auto text-white'>

          <div className='flex items-center gap-3'>
            <p className='text-[18px]'>Call Us At:</p>
            <GiRotaryPhone fontSize={28} />
            <ul className='flex gap-3'>
              {
                rpdata?.dbPrincipal?.phones?.map((phone, index) => {
                  return (
                    <li key={index}>
                      <a href={`tel:+1${phone.phone}`}>
                        {phone.phone}
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>

          <ul className='flex'>
            {
              rpdata?.dbPrincipal?.emails?.map((email, index) => {
                return (
                  <li key={index}>
                    <a href={`tel:+1${email.email}`}>
                      {email.email}
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>

      <div className='bgPrincipal svgHeader1Info w-[98%] md:w-[65%] mr-auto py-7'></div>
      <div className='bgSecondary svgHeader1Img w-[90%] md:w-[40%] mr-auto py-3 -mt-14 md:-mt-28'>
        <div className='w-[80%] md:w-[60%] mr-auto pl-3 py-3 md:p-0 md:mx-auto'>
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt='logo images'
            className='w-full lg:w-[70%]'
          />
        </div>
      </div>
      <div className='bgPrincipal svgHeader1Info w-[60%] md:w-[25%] mr-auto py-3'></div>
      <div className='bgSecondary svgHeader1block w-[40%] md:w-[10%] mr-auto py-5 md:py-7 -mt-7'></div>

      {
        isOpen ?
          <div className='fixed bg-[#222] z-20 top-0 left-0 h-screen w-full ease-in-out duration-700'
          >
            <div className="flex flex-col justify-center items-center h-full w-full">
              <picture className="flex justify-center items-center">
                <img
                  src={rpdata?.dbPrincipal?.logo}
                  alt='logo'
                  className="w-[50%]  mb-10"
                />
              </picture>
              <div className='w-full' onClick={() => setOpen(!isOpen)}>
                <MenuList
                  classes={'w-full  h-[55vh] overflow-y-auto text-center text-white font-semibold text-[19px] flex flex-col gap-y-5'}
                />
              </div>
              <div>
                <spa className='capitalize font-semibold text-white pt-10'>Follow us: </spa>
                <IconsRedes classes={'flex justify-center items-center gap-5'} />
              </div>
            </div>

          </div>
          : null
      }

    </section>
  )
}

export default HeaderContent